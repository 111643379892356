import React, { Fragment, useEffect, useState } from 'react';
import "../assets/tcdelhi.scss";
import bgImg1 from "../images/tc/bg-1.webp"
import img1 from "../images/tc/img-1.jpg";
import img2 from "../images/tc/img-2.png";
import img3 from "../images/tc/img-3.png";
import img4 from "../images/tc/img-4.png";
import bgImg2 from "../images/tc/bg-2.png";
import img5 from "../images/tc/img-5.png";
import img6 from "../images/tc/img-6.png";
import img7 from "../images/tc/img-7.png";
import img8 from "../images/tc/img-8.png";
import img9 from "../images/tc/img-9.png";
import img10 from "../images/tc/img-10.png"
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';




const Thanku = () => {
    return (
        <Fragment>
            <div id="BlockUIConfirm" class="BlockUIConfirm tcncr">
                <div class="blockui-mask"></div>
                <div class="RowDialogBody">
                    <div class="confirm-header row-dialog-hdr-success">
                        Thank You
                    </div>
                    <div class="confirm-body">
                        Thank you for filling out your information!
                    </div>
                    <div class="confirm-btn-panel pull-right">
                        <a href='/'>Back to home</a>
                    </div>
                </div>
            </div>
        </Fragment>

    )
}





const TermiteControlInDelhiNcr = () => {
    const [isBookingDone, setIsBookingDone] = useState(false);
    const [values, setValues] = useState({ Email: "", Phone: "", Name: "" });
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        setIsBookingDone(window.localStorage.getItem("isBookingDone") || false);
    }, []);


    const handleSubmitForm = async (e) => {
        e.preventDefault();
        if (!isLoading) {
            setIsLoading(true);
            try {
                let res = await axios.request({
                    method: "POST",
                    url: "https://dashboard.pestgogo.com/backend-api/email/send-lead-email",
                    data: values
                });
                window.dataLayer = window.dataLayer || [];
                dataLayer.push({event: "generate_lead"})
                setIsBookingDone(true)
            } catch (error) {
                return toast.error(error?.response?.data?.message || error.message)
            } finally {
                setIsLoading(false);
            }

        }
    }

    const onInputChange = (e) => {
        let { name, value } = e.target;
        setValues(pre => ({ ...pre, [name]: value }));
    }


    const onPhoneChange = (e) => {
        let { value } = e.target;
        if (!isNaN(Number(value)) || value?.trim() == "") {
            setValues(pre => ({ ...pre, Phone: value?.trim() }));
        }
    }

    return (
        <div className='tcncr'>
            <Toaster />
            {isBookingDone && <Thanku />}
            <section className="mrket__tc--main tcncr">
                <img className="bg position-absolute" src={bgImg1} width="1600" loading="lazy" />
                <div className="mrket__tc--content mx-auto justify-between align-center d-flex">
                    <div className="basis-50 lft">
                        <h1>Looking for <span>TERMITE CONTROL</span> SERVICE</h1>
                        <p>At Pestgogo, we take Termite Control to the next level with our cutting-edge <span>Termatrac Radar
                            Camera</span></p>
                        <a className="cta-btn" href='#ctamf'>Get free quote</a>
                    </div>
                    <div className="basis-50 rgt">
                        <img src={img1} width="540" loading="lazy" style={{borderRadius:"45px"}} />
                    </div>
                </div>
            </section>

            {/* <!-- mrket contact form --> */}
            <section className="mrket__tc--contact">
                <div className="d-flex mx-auto">
                    <div className="lft basis-50">
                        <h2>Protect Your Home Inside Out</h2>
                        <h3>+91 9560450376</h3>
                        <p>Schedule a free inspection of your places and effectively rid your life of pests!</p>
                        <a className="cta-btn">Call Us</a>
                    </div>
                    <div className="rgt basis-50">
                        <form onSubmit={handleSubmitForm} id='ctamf'>
                            <h4>Get Free Consultation</h4>
                            <div>
                                <label>
                                    <span>Name</span>
                                    <input value={values.Name} minLength="3" type="text" name="Name" onChange={onInputChange} required />
                                </label>
                                <label>
                                    <span>Email</span>
                                    <input value={values.Email} type="email" name="Email" onChange={onInputChange} required />
                                </label>
                                <label>
                                    <span>Phone</span>
                                    <input value={values.Phone} type="text" name="Phone" maxLength="10" minLength="10" onChange={onPhoneChange} required />
                                </label>
                                <button type='submit' className="cta-btn">{isLoading ? "Loading..." : "Submit"}</button>
                            </div>
                        </form>
                    </div>
                </div>

            </section>

            <section className="mrket__tc--how mx-auto">
                <div className="mrkt-head">
                    <h2>How we work?</h2>
                    <h3>We use <span>Termite Radar</span> Camera to detect and provide solutions for pest control service</h3>
                </div>
                <div className="mrket-cards">
                    <div className="mrket-card">
                        <div>
                            <img src={img2} width="163" />
                        </div>
                        <div>
                            <h4>Non-Invasive</h4>
                            <p>No need to drill or damage your walls! Our radar camera technology ensures a minimally invasive
                                inspection process.</p>
                        </div>
                    </div>
                    <div className="mrket-card">
                        <div className="ordr">
                            <img src={img3} width="163" />
                        </div>
                        <div>
                            <h4>Accurate Detection</h4>
                            <p> Our radar camera leaves no termites undetected, saving you time and money on unnecessary
                                treatments.</p>
                        </div>
                    </div>
                    <div className="mrket-card">
                        <div>
                            <img src={img4} width="163" />
                        </div>
                        <div>
                            <h4>Eco-Friendly</h4>
                            <p>
                                Our targeted approach minimizes the use of chemicals, prioritizing the safety of your family and
                                the environment.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mrket__tc--dmg">
                <img className="position-absolute" src={bgImg2} width="100%" />
                <div className="mrk-content">
                    <h2>Keep Your Properties Safe From Damaging Pests.</h2>
                    <p>Our advanced technology allows us to accurately detect and pinpoint termites hidden deep within your
                        walls, ensuring thorough elimination for a termite-free environment.</p>
                    <a className="cta-btn" href='#ctamf'>Get Quote</a>
                </div>
            </section>


            <section className="mrk-control">
                <div className="d-flex mx-auto">
                    <div className="lft">
                        <img src={img5} width="630px" />
                    </div>
                    <div className="rgt">
                        <h2>Here’s what our pest control customers say</h2>
                        <p>With years of experience in pest control, we have successfully helped countless customers overcome
                            pest problems. Our client’s testimonials reflect our expertise and dedication to customer
                            satisfaction.</p>
                        <div className="control-cards d-flex">
                            <div className="control-card">
                                <p>"PestGoGo's Termatrac Radar Camera was a game-changer! They identified termite colonies we
                                    had no idea existed and eradicated them effectively. Their team was professional and ensured
                                    our property was treated with care. Highly recommended!"</p>
                                <div className="d-flex" style={{ padding: "0 !important" }}>
                                    <div>
                                        <img src={img6} width="52px" />
                                    </div>
                                    <div>
                                        <p style={{ color: "#FB2717" }}>Rahul S</p>
                                        <p style={{ color: "#252525", marginTop: "3px" }}>Gurgaon, Delhi NCR</p>
                                    </div>
                                </div>
                            </div>

                            <div className="control-card">
                                <p>"I was skeptical at first, but PestGoGo's Termatrac Radar Camera blew me away! They showed me
                                    the exact locations of termites, and their treatment plan worked like a charm. It's a relief
                                    to know that my home is now termite-free!"</p>
                                <div className="d-flex" style={{ padding: " 0 !important" }}>
                                    <div>
                                        <img src={img7} width="52px" />
                                    </div>
                                    <div>
                                        <p style={{ color: "#FB2717" }}>Shreya R.</p>
                                        <p style={{ color: "#252525; margin-top: 3px" }}>Gaziabaad, Delhi NCR</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>


            <section className="mrk-steps">
                <h2>Our 3-Step Process Plan</h2>
                <p>
                    Don't let termites stay hidden and cause further damage to your property! Experience the power of the
                    Termatrac Radar Camera with PestGoGo.
                </p>
                <div className="mrk-steps-flex d-flex">
                    <div className="mrk-step">
                        <img src={img8} width="75px" loading="lazy" />
                        <h3>Contact Us</h3>
                        <p> Fill the form or call us to schedule a consultation.</p>
                    </div>
                    <div className="mrk-step">
                        <img src={img9} width="75px" loading="lazy" />
                        <h3>Free Inspection</h3>
                        <p>Our experts will visit your property to assess the pest situation.</p>
                    </div>
                    <div className="mrk-step">
                        <img src={img10} width="75px" loading="lazy" />
                        <h3>Effective Pest Control</h3>
                        <p>We’ll implement a tailored pest control solution to safeguard your property.</p>
                    </div>
                </div>
                <div className="mrk-step-bottom">
                    <p>
                        Our dedicated team is always active and gives you the best services.
                    </p>
                    <p className="p">Feel free to call us, +91 9560450376</p>
                    <a className="cta-btn" href='#ctamf'>Get Quote</a>
                </div>
            </section>
        </div>

    );
}

export default TermiteControlInDelhiNcr;
